<template>
  <div class="list-item">
    <div class="list-item__date">
      <div class="list-item__date__day">{{ eventDay }}</div>
      <div class="list-item__date__month">{{ eventMonth }}</div>
    </div>
    <div class="list-item__info-container">
      <h2 class="list-item-info__title">{{ eventData.act.act_name }}</h2>
      <h3 v-if="hasActs" class="list-item-info__title">{{ acts }}</h3>
      <div class="list-item-info__chip-container">
        <span class="list-item-info__chip"> <ClockIcon /> {{ time }} </span>
        <span class="list-item-info__chip">
          <LocationIcon /> {{ eventData.venue.name }} - {{ eventData.venue.place }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { nl } from 'date-fns/locale'
import ClockIcon from '../assets/clock.svg'
import LocationIcon from '../assets/location.svg'

export default {
  components: {
    ClockIcon,
    LocationIcon,
  },
  props: {
    eventData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    eventMonth() {
      return format(new Date(this.eventData.date_performance), 'MMM', { locale: nl })
    },
    eventDay() {
      return format(new Date(this.eventData.date_performance), 'dd')
    },
    time() {
      return `${this.eventData.time_performance_start.slice(0, 5)} - 
      ${this.eventData.time_performance_finished.slice(0, 5)}`
    },
    hasActs() {
      return this.eventData.act_persons.length > 0
    },
    acts() {
      return `met ${this.eventData.act_persons.map((act) => act.name).join(', ')}`
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  font-family: 'Cervo';
  text-transform: uppercase;
  letter-spacing: -0.23px;
  font-weight: 800;
  font-size: 2rem;
  margin: 0 !important;
  color: rgb(var(--q-grey));
}

h3 {
  font-family: 'Cervo';
  text-transform: uppercase;
  letter-spacing: -0.23px;
  color: rgb(var(--q-grey));
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0 !important;
}

p {
  font-family: 'Qarla';
  color: rgb(var(--q-red));
}

.list-item {
  background-color: rgb(var(--q-grey-light));
  border-radius: 12px;
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: row;
  align-items: center;

  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Cervo';
    text-transform: uppercase;
    font-weight: 800;
    background-color: rgb(var(--q-red));
    color: white;
    height: 120px;
    aspect-ratio: 1/1;
    border-radius: 12px;

    &__day {
      font-size: 4rem;
      line-height: 3.5rem;
      margin: 0;
      margin-bottom: -7px;
    }

    &__month {
      margin: 0;
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: -7px;
    }
  }

  &__info-container {
    padding: 10px 0 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .list-item-info {
    &__chip-container {
      padding: 10px 0;
      display: flex;
      gap: 10px;
    }

    &__chip {
      font-family: 'Qarla';
      border-radius: 12px;
      background-color: rgb(var(--q-grey));
      color: white;
      padding: 5px 10px;
      font-size: 0.9rem;
      width: fit-content;
      display: flex;
      gap: 5px;
      align-content: center;

      svg {
        fill: white;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
</style>
