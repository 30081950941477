<template>
  <div class="events-calendar">
    <Event v-for="event in events" :key="event.uid" :eventData="event" />
  </div>
</template>

<script>
import axios from 'axios'
import Event from './components/Event.vue'

const url = 'https://digiboeken.kyudo-events.nl/qmusic/bookings.json'

export default {
  components: { Event },
  data() {
    return {
      events: [],
    }
  },
  async mounted() {
    const response = await axios.get(
      `https://api-request-cacher.radio.dpgmedia.cloud/default/api-request-cacher?url=${url}`
    )
    this.events = response.data
  },
}
</script>

<style lang="scss" scoped>
.events-calendar {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
</style>
